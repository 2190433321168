import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser, socialLogin } from "../../store/actions";
import logoLight from "../../assets/images/logo-light.png";

const ResetPasswordPage = (props) => {
    const [showpwd, setshowpwd] = useState(false)
    const dispatch = useDispatch();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Please Enter Your password"),
            confirmPassword: Yup.string().required("Please Confirm Your Password"),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.history));
        }
    });

    const { error } = useSelector(state => ({
        error: state.Login.error,
    }));
    useEffect(() => {
        if (error) {
            Swal.fire({
                title: "Login",
                text: error,
                icon: 'warning',
                confirmButtonText: 'Ok'
            }).then(res => {
                if (error == "User Not found.") {
                    props.history.push("/register")
                }
            });
        }
    }, [error])
   
    const { apiError } = useSelector(state => ({
        // user: state.Account.user,
        apiError: state.Login.apiError,
    }));

    document.title = "Reset Password | Artsoot";
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/home" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="30" />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Reset Password</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Create a new password for your account.</h5>
                                            {/* <p className="text-muted"></p> */}
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                action="#">
                                                {apiError && (
                                                    <Alert color="danger"><div>{apiError}</div></Alert>
                                                )}

                                                <div className="mb-3">
                                                    <Label htmlFor="password" className="form-label">New Password</Label>
                                                    <Input
                                                        name="password"
                                                        className="form-control"
                                                        placeholder="Enter New Password"
                                                        autoComplete="username"
                                                        type="password"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password || ""}
                                                        invalid={
                                                            validation.touched.password && validation.errors.password ? true : false
                                                        }
                                                    />
                                                    {validation.touched.password && validation.errors.password ? (
                                                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="password-input">Confirm Password</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="confirmPassword"
                                                            value={validation.values.confirmPassword || ""}
                                                            type={showpwd ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            autoComplete="current-password"
                                                            placeholder="Confirm New Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                                                            }
                                                        />
                                                        {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                                            <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <Button color="success" className="btn btn-success w-100" type="submit">Reset Password</Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="mt-4 text-center">
                                    <p className="mb-0">Remembered your password? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Click here </Link> </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(ResetPasswordPage);