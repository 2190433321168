/* eslint-disable import/first */
import React, { useState, useEffect, Component } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Modal, ModalHeader, Button, ModalBody, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Nav, NavItem, NavLink, Pagination, PaginationItem, ButtonGroup, PaginationLink, Progress, Row, TabContent, Table, TabPane, UncontrolledCollapse, UncontrolledDropdown } from 'reactstrap';

// import { Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledButtonDropdown, UncontrolledDropdown, } from 'reactstrap';

import classnames from 'classnames';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import { Grid, _ } from 'gridjs-react';
import ReactPlayer from 'react-player'
import { Media, Player, controls } from 'react-media-player';
const { PlayPause, SeekBar, Duration, MuteUnmute, Volume, Fullscreen } = controls;

// import 'react-media-player/lib/styles.css';

//Images
import avatar1 from "../../../../assets/images/users/user-dummy-img.jpg";
import { createVideos, deleteVideo, getAllVideos, getLoggedInUser, createAuctions, updateAuction, updateVideo, getAllAuctions, getAllBids, deleteAuction, createPayments, getUserDetail } from '../../../../helpers/fakebackend_helper';
import AuctionCard from '../../../Widgets/AuctionCard';
import Swal from 'sweetalert2';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PayPalModal from './paypalCheckout';
import AuctionSimpleCard from '../../../Widgets/AuctionSimpleCard';

const SimplePage = () => {
    let params = useParams();

    SwiperCore.use([Autoplay]);

    const user = getLoggedInUser();

    const [activeTab, setActiveTab] = useState('1');
    const [activityTab, setActivityTab] = useState('1');
    const [activeArtistTab, setActiveArtistTab] = useState("1");
    const [activeBuyerTab, setActiveBuyerTab] = useState("1");
    const [loadingVideo, setLoadingVideo] = useState(false);

    const artistTabChange = (tab) => {
        if (activeArtistTab !== tab) setActiveArtistTab(tab);
    };
    const buyerTabChange = (tab) => {
        if (activeBuyerTab !== tab) setActiveBuyerTab(tab);
    };

    const [modal_standard, setmodal_standard] = useState(false);
    function tog_standard() {
        setmodal_standard(!modal_standard);
        setSelectForm([]);
    }
    const [modal_auction, setmodal_auction] = useState(false);
    function tog_auction(video) {
        if (video) {
            setAuctionForm({ ...auctionForm, video_id: video.id, image_url: JSON.parse(video.video_url)[0] });
        }
        setmodal_auction(!modal_auction);
    }

    const [modal_checkout, setmodal_checkout] = useState(false);
    const [checkoutFormData, setCheckoutFormData] = useState(false);
    function tog_checkout(bid) {
        if (bid) {
            var temp = bid.auction;
            temp['bid_id'] = bid.id;

            setCheckoutFormData(temp);
            // setAuctionForm({ ...auctionForm, video_id: bid['cells'][0]['data'], image_url: JSON.parse(bid['cells'][2]['data'])[0] });
        }
        setmodal_checkout(!modal_checkout);
    }


    const [showPaypalCheckoutModal, setShowPaypalCheckoutModal] = useState(true);

    const openModal = () => {
        setShowPaypalCheckoutModal(true);
    };

    const closeModal = () => {
        setShowPaypalCheckoutModal(false);
    };

    const [userDetail, setUserDetail] = useState({
        "firstName": "",
        "lastName": "",
        "email": "",
        "phone": "",
        "info": {
            paymentAddress: "",
            country: "",
            city: "",
            // zipCode: "",
            description: "",
            avatar: ""
        },
    });




    const [videos, setVideos] = useState([]);
    const [videoForm, setVideoForm] = useState({
        "title": "",
        "description": "",
        "duration": 0,
        "dimension": "",
        "reserve_price": "0",
        "shipping_cost": "0",
    });

    const [auctions, setAuctions] = useState([]);
    const [auctionForm, setAuctionForm] = useState({
        "image_url": "",
        "video_id": "",
        "start_datetime": "",
        "auction_timeframe": 1,
        "end_datetime": "",
        "reserve_price": "0",
        "shipping_cost": "0"
    });
    const [selectForm, setSelectForm] = useState([]);

    const [bids, setBids] = useState([]);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const toggleActivityTab = (tab) => {
        if (activityTab !== tab) {
            setActivityTab(tab);
        }
    };

    const secondsToMinutesAndSeconds = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    }

    const handleSelectFormChange = (event) => {
        if (selectForm.includes(event.target.value) || selectForm.length >= 3) return;

        setSelectForm([...selectForm, event.target.value]);
    }

    const deleteSelectedForm = (selectedItem) => {
        let newSelectedForm = selectForm.filter((item) => item !== selectedItem)
        setSelectForm(newSelectedForm);
    }

    const editVideo = (video) => {
        Swal.fire({
            title: 'Edit Video Details',
            html: `
                <input id="swal-input-title" class="swal2-input" placeholder="Title" value="${video.title}" style="width: -webkit-fill-available;">
                <input id="swal-input-description" class="swal2-input" placeholder="Description" value="${video.description}" style="width: -webkit-fill-available;">
            `,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            preConfirm: () => {
                const title = document.getElementById('swal-input-title').value;
                const description = document.getElementById('swal-input-description').value;
                // const duration = document.getElementById('swal-input-duration').value;

                if (!title || !description) {
                    Swal.showValidationMessage('Please fill in all fields');
                } else {
                    // Update the video with the new details
                    return updateVideo(video.id, { title, description })
                        .then(() => {
                            setVideos(prevVideos =>
                                prevVideos.map(v => v.id === video.id ? { ...v, title, description } : v)
                            );
                            Swal.fire('Success!', 'Video details updated.', 'success');
                        })
                        .catch((error) => {
                            Swal.showValidationMessage(`Error: ${error.message}`);
                        });
                }
            }
        });
    };
    const handleDeleteVideo = (video) => {
        Swal.fire({
            title: 'Delete!',
            text: "Are you sure you want to delete this video?",
            icon: 'warning',
            showCancelButton: true, // Display the "Cancel" button
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel', // Text for the "Cancel" button
        }).then(res => {
            if (res.isConfirmed) {
                deleteVideo(video.id).then(res => {
                    setVideos(videos.filter(e => e['id'] != video.id));
                    loadVideo();
                    loadAuction();
                })
            }
        })

    }
    const handleDeleteAuction = (auction) => {
        Swal.fire({
            title: 'Delete!',
            text: "Are you sure you want to delete this auction?",
            icon: 'warning',
            showCancelButton: true, // Display the "Cancel" button
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel', // Text for the "Cancel" button
        }).then(res => {
            if (res.isConfirmed) {
                deleteAuction(auction.id).then(res => {
                    // setAuctions(auctions.filter(e => e['id'] != auction.id));
                    loadVideo();
                    loadAuction();
                })
            }
        })
    }

    const handleAddTrackingNumber = (auction) => {
        Swal.fire({
            title: 'Input Tracking Number',
            input: 'text',
            inputLabel: 'Enter the tracking number for this auction',
            inputPlaceholder: 'Tracking number',
            showCancelButton: true,
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            preConfirm: (trackingNumber) => {
                if (!trackingNumber) {
                    Swal.showValidationMessage('Please enter a tracking number');
                } else {
                    // Here you can handle the tracking number submission
                    // For example, you could call a function to update the auction
                    updateAuction(auction.id, { "tracking_number": trackingNumber })
                        .then(() => {
                            setAuctions(prevState => prevState.map((item) => item.id === auction.id ? { ...item, tracking_number: trackingNumber } : item));
                            Swal.fire('Success!', 'Tracking number updated.', 'success');
                        })
                        .catch((error) => {
                            Swal.showValidationMessage(`Error: ${error.message}`);
                        });
                }
            }
        });
    };

    const downloadVideo = (video) => {

        const link = document.createElement('a');
        link.href = JSON.parse(video['video_url'])[1];
        link.target = '_blank'; // Open the link in a new tab (optional)
        link.download = 'video' + video.id; // Set the default filename if not provided

        document.body.appendChild(link);
        link.click();

        // Clean up the link element
        document.body.removeChild(link);
    }

    const handleBuyAuction = (bid) => {
        // deleteAuction(row['cells'][0]['data']).then(res => {
        //     setAuctions(auctions.filter(e => e['id'] != row['cells'][0]['data']));
        // })
    }
    // const createAuction = (row) => {
    //     console.log(row);
    // }
    const handleAuctionFormSubmit = (e) => {
        e.preventDefault();
        var startTime = new Date(convertDateTime(auctionForm['start_datetime']));
        var endTime = new Date(startTime.getTime() + (parseInt(auctionForm['auction_timeframe']) * 24 * 60 * 60 * 1000));
        createAuctions({
            ...auctionForm,
            start_datetime: startTime,
            end_datetime: endTime,
            status: "pending",
        }).then(res => {

            Swal.fire({
                title: 'Thank you!',
                text: "Your video is going through the approval process",
                icon: 'info',
                confirmButtonText: 'Ok'
            }).then(res => {
                loadVideo();
                loadAuction();
                setmodal_auction(false);
                setActiveArtistTab("2")
                setAuctionForm({
                    "image_url": "",
                    "video_id": "",
                    "start_datetime": "",
                    "auction_timeframe": 1,
                    "end_datetime": "",
                    "reserve_price": "0",
                    "shipping_cost": "0"
                })
            })
        })
    }
    const handleVideoFormSubmit = (e) => {
        e.preventDefault();
        // Check if the user already has 3 uploaded videos
        if (videos.length >= 3) {
            Swal.fire({
                title: 'Warning!',
                text: "You can only upload a maximum of 3 videos.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
            return;
        }
        var formData = new FormData();
        Object.keys(videoForm).forEach(function (key, index) {
            if (key != 'image_url' && key != 'video_url') {
                formData.append(key, videoForm[key]);
            }
        });
        formData.append("category", JSON.stringify(selectForm));
        formData.append("files", videoForm['image_url']);
        formData.append("files", videoForm['video_url']);

        setLoadingVideo(true)
        createVideos(formData).then(res => {
            // var temp = [...videos];
            // res['createdAt'] = new Date(res['createdAt']).toLocaleString();
            // res['duration'] = secondsToMinutesAndSeconds(res['duration']);
            // temp.push(res);
            // setVideos(temp);


            setLoadingVideo(false)
            Swal.fire({
                title: 'Success!',
                text: "Video uploaded successfully",
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(res => {
                tog_standard();
                loadVideo();
                loadAuction();
                setVideoForm({
                    "title": "",
                    "description": "",
                    "duration": 0,
                    "dimension": "",
                    "reserve_price": "",
                    "shipping_cost": "",
                })
            })
        }).catch(err => {
            setLoadingVideo(false)
            Swal.fire({
                title: 'Warn!',
                text: "Uploading failed",
                icon: 'warn',
                confirmButtonText: 'Ok'
            }).then(res => {
                tog_standard();
            })
        })
    }
    const handleVideoFormChange = (e) => {
        const { name, value } = e.target;
        if (name != 'image_url' && name != 'video_url') {
            setVideoForm({
                ...videoForm,
                [name]: value,
            });
        } else {
            setVideoForm({
                ...videoForm,
                [name]: e.target.files[0],
            });
        }
    }
    const handleAuctionFormChange = (e) => {
        const { name, value } = e.target;
        setAuctionForm({
            ...auctionForm,
            [name]: value,
        });
    }

    const convertDateTime = (dateString) => {

        // Split the string into date and time parts
        const [datePart, timePart] = dateString.split("T");

        // Split the date part into year, month, and day
        const [year, month, day] = datePart.split("-").map(Number);

        // Split the time part into hours and minutes
        const [hours, minutes] = timePart.split(":").map(Number);

        // Create a new Date object with the parsed values
        const dateObject = new Date(year, month - 1, day, hours, minutes);
        return dateObject;
    }

    const convertDBDateTime = (dateString) => {

        // Split the string into date and time parts
        const [datePart, timePart] = dateString.split(", ");

        // Split the date part into year, month, and day
        const [day, month, year] = datePart.split("/").map(Number);

        // Split the time part into hours and minutes
        const [hours, minutes, secs] = timePart.split(":").map(Number);

        // Create a new Date object with the parsed values
        const dateObject = new Date(year, month - 1, day, hours, minutes);
        return dateObject;
    }

    useEffect(() => {

        loadVideo();
        loadAuction();
        loadBids();

        getUserDetail().then(res => {
            if (res) {
                if (!res['info']) {
                    res['info'] = userDetail.info;
                } else {
                    res['info'] = { ...userDetail.info, ...JSON.parse(res['info']) };
                }
                setUserDetail(res);
            }
        })


        return () => {
        }
    }, [])

    const loadVideo = () => {
        getAllVideos().then(res => {
            var temp = [...res];
            temp = temp.map(e => {
                e['createdAt'] = new Date(e['createdAt']).toLocaleString();
                e['duration'] = secondsToMinutesAndSeconds(e['duration']);
                return e;
            })
            setVideos(temp);
        })
    }


    const loadBids = () => {
        getAllBids().then(res => {
            var temp = [...res];
            temp = temp.map(e => {
                e['createdAt'] = new Date(e['createdAt']).toLocaleString();
                // e['auction']['start_datetime'] = convertDateTime(e['auction']['start_datetime']).toLocaleString();
                // e['auction']['end_datetime'] = convertDateTime(e['auction']['end_datetime']).toLocaleString();
                e['bidders'] = e['auction']['bids'].length;
                return e;
            })

            if (params.bidId) {
                toggleTab('3');
                tog_checkout(temp.filter(e => e.id == params.bidId)[0])
            }

            setBids(temp);
        })
    }

    const loadAuction = () => {
        getAllAuctions().then(res => {
            var temp = [...res];
            temp = temp.map(e => {
                e['createdAt'] = new Date(e['createdAt']).toLocaleString();
                e['start_datetime'] = new Date(e['start_datetime']).toLocaleString();
                e['end_datetime'] = new Date(e['end_datetime']).toLocaleString();
                return e;
            })
            setAuctions(res);
        })
    }

    document.title = "Profile | Artsoot";

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    <div className="profile-foreground position-relative mx-n4 mt-n4">
                        <div className="profile-wid-bg">
                            {/* <img src={profileBg} alt="" className="profile-wid-img" /> */}
                        </div>
                    </div>
                    <div className="pt-4 ">
                        <Row className="g-4">
                            <div className="col-auto">
                                <div className="avatar-lg">
                                    <img src={userDetail.info.avatar ? userDetail.info.avatar : avatar1} alt="user-img"
                                        className="img-thumbnail rounded-circle user-profile-image" />
                                </div>
                            </div>

                            <Col>
                                <div className="p-2">
                                    <h3 className="text-white mb-1">{userDetail.firstName} {userDetail.lastName}</h3>
                                    {/* <p className="text-white-75">Owner & Founder</p> */}
                                    <div className="hstack text-white-50 gap-1">
                                        <div className="me-2"><i
                                            className="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"></i>{userDetail.info.city ?? ""}, {userDetail.info.country ?? ""}</div>
                                        {/* <div>
                                            <i
                                            className="ri-building-line me-1 text-white-75 fs-16 align-middle"></i>Pac
                                        </div> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        <Col lg={12}>
                            <div>
                                <div className="d-flex">
                                    <Nav pills className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                                        role="tablist">
                                        <NavItem>
                                            <NavLink
                                                href="#overview-tab"
                                                className={classnames({ active: activeTab === '1' })}
                                                onClick={() => { toggleTab('1'); }}
                                            >
                                                <i className="ri-airplay-fill d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Overview</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#Artist_profiles"
                                                className={classnames({ active: activeTab === '2' })}
                                                onClick={() => { toggleTab('2'); }}
                                            >
                                                <i className="ri-list-unordered d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Artist profiles</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#Buyer_profiles"
                                                className={classnames({ active: activeTab === '3' })}
                                                onClick={() => { toggleTab('3'); }}
                                            >
                                                <i className="ri-price-tag-line d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Buyer profiles</span>
                                            </NavLink>
                                        </NavItem>
                                        {/* <NavItem>
                                            <NavLink
                                                href="#Payment_settings"
                                                className={classnames({ active: activeTab === '4' })}
                                                onClick={() => { toggleTab('4'); }}
                                            >
                                                <i className="ri-folder-4-line d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Payment settings</span>
                                            </NavLink>
                                        </NavItem> */}
                                    </Nav>
                                    <div className="flex-shrink-0">
                                        <Link to="/pages-profile-settings" className="btn btn-success"><i
                                            className="ri-edit-box-line align-bottom"></i> Edit Profile</Link>
                                    </div>
                                </div>

                                <TabContent activeTab={activeTab} className="pt-4">
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col xxl={3}>
                                                {/* <Card>
                                                    <CardBody>
                                                        <h5 className="card-title mb-5">Complete Your Profile</h5>
                                                        <Progress value={30} color="danger" className="animated-progess custom-progress progress-label" ><div className="label">30%</div> </Progress>
                                                    </CardBody>
                                                </Card> */}

                                                <Card>
                                                    <CardBody>
                                                        <div className="table-responsive">
                                                            <Table className="table-borderless mb-0">
                                                                <tbody>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">Full Name :</th>
                                                                        <td className="text-muted">{userDetail.firstName} {userDetail.lastName}</td>
                                                                    </tr>
                                                                    {/* <tr>
                                                                        <th className="ps-0" scope="row">Mobile :</th>
                                                                        <td className="text-muted">{userDetail.phone ?? ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">E-mail :</th>
                                                                        <td className="text-muted">{userDetail.email}</td>
                                                                    </tr> */}
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">Location :</th>
                                                                        <td className="text-muted">{userDetail.info.city ?? ""}, {userDetail.info.country ?? ""}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">Joining Date</th>
                                                                        <td className="text-muted">{new Date(userDetail.createdAt).toLocaleDateString()}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </CardBody>
                                                </Card>


                                                {/* <Card>
                                                    <CardBody>
                                                        <div className="d-flex align-items-center mb-4">
                                                            <div className="flex-grow-1">
                                                                <h5 className="card-title mb-0">Popular Auctions</h5>
                                                            </div>
                                                            <div className="flex-shrink-0">
                                                                <UncontrolledDropdown direction='start'>
                                                                    <DropdownToggle tag="a" id="dropdownMenuLink1" role="button">
                                                                        <i className="ri-more-2-fill fs-14"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem>View</DropdownItem>
                                                                        <DropdownItem>Edit</DropdownItem>
                                                                        <DropdownItem>Delete</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mb-4">
                                                            <div className="flex-shrink-0">
                                                                <img src={smallImage4} alt=""
                                                                    height="50" className="rounded" />
                                                            </div>
                                                            <div className="flex-grow-1 ms-3 overflow-hidden">
                                                                <Link to="#">
                                                                    <h6 className="text-truncate fs-14">Design your apps in
                                                                        your own way</h6>
                                                                </Link>
                                                                <p className="text-muted mb-0">15 Dec 2021</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mb-4">
                                                            <div className="flex-shrink-0">
                                                                <img src={smallImage5} alt=""
                                                                    height="50" className="rounded" />
                                                            </div>
                                                            <div className="flex-grow-1 ms-3 overflow-hidden">
                                                                <Link to="#">
                                                                    <h6 className="text-truncate fs-14">Smartest
                                                                        Applications for Business</h6>
                                                                </Link>
                                                                <p className="text-muted mb-0">28 Nov 2021</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="flex-shrink-0">
                                                                <img src={smallImage6} alt=""
                                                                    height="50" className="rounded" />
                                                            </div>
                                                            <div className="flex-grow-1 ms-3 overflow-hidden">
                                                                <Link to="#">
                                                                    <h6 className="text-truncate fs-14">How to get creative
                                                                        in your work</h6>
                                                                </Link>
                                                                <p className="text-muted mb-0">21 Nov 2021</p>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card> */}
                                            </Col>
                                            <Col xxl={9}>
                                                <Card>
                                                    <CardBody>
                                                        <h5 className="card-title mb-3">About</h5>
                                                        <p>{userDetail.info.description ?? ""}</p>
                                                        {/* <Row>
                                                            <Col xs={6} md={4}>
                                                                <div className="d-flex mt-4">
                                                                    <div
                                                                        className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                        <div
                                                                            className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                            <i className="ri-user-2-fill"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 overflow-hidden">
                                                                        <p className="mb-1">Designation :</p>
                                                                        <h6 className="text-truncate mb-0">Video artist</h6>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col xs={6} md={4}>
                                                                <div className="d-flex mt-4">
                                                                    <div
                                                                        className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                        <div
                                                                            className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                            <i className="ri-global-line"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 overflow-hidden">
                                                                        <p className="mb-1">Website :</p>
                                                                        <Link to="#" className="fw-semibold">www.reyes.com</Link>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row> */}
                                                    </CardBody>
                                                </Card>

                                                {/* <Row>
                                                    <Col lg={12}>
                                                        <Card>
                                                            <CardHeader className="align-items-center d-flex">
                                                                <h4 className="card-title mb-0  me-2">Recent Activity</h4>
                                                                <div className="flex-shrink-0 ms-auto">
                                                                    <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                                                        role="tablist">
                                                                        <NavItem>
                                                                            <NavLink
                                                                                to="#today-tab"
                                                                                className={classnames({ active: activityTab === '1' })}
                                                                                onClick={() => { toggleActivityTab('1'); }}
                                                                            >
                                                                                Today
                                                                            </NavLink>
                                                                        </NavItem>
                                                                        <NavItem>
                                                                            <NavLink
                                                                                to="#weekly-tab"
                                                                                className={classnames({ active: activityTab === '2' })}
                                                                                onClick={() => { toggleActivityTab('2'); }}
                                                                            >
                                                                                Weekly
                                                                            </NavLink>
                                                                        </NavItem>
                                                                        <NavItem className="nav-item">
                                                                            <NavLink
                                                                                to="#monthly-tab"
                                                                                className={classnames({ active: activityTab === '3' })}
                                                                                onClick={() => { toggleActivityTab('3'); }}
                                                                            >
                                                                                Monthly
                                                                            </NavLink>
                                                                        </NavItem>
                                                                    </Nav>
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row> */}
                                            </Col>
                                        </Row>
                                    </TabPane>

                                    <TabPane tabId="2">
                                        <Card>
                                            <CardHeader>
                                                <h5 className="card-title mb-3">Artist profiles</h5>

                                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                                    role="tablist">
                                                    <NavItem>
                                                        <NavLink
                                                            to="#"
                                                            className={classnames({ active: activeArtistTab === "1" })}
                                                            onClick={() => {
                                                                artistTabChange("1");
                                                            }}
                                                            type="button"
                                                        >
                                                            <i className="fas fa-home"></i>
                                                            Videos
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink to="#"
                                                            className={classnames({ active: activeArtistTab === "2" })}
                                                            onClick={() => {
                                                                artistTabChange("2");
                                                            }}
                                                            type="button">
                                                            <i className="far fa-user"></i>
                                                            Auctions
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </CardHeader>
                                            <CardBody>
                                                <TabContent activeTab={activeArtistTab}>
                                                    {showPaypalCheckoutModal && <PayPalModal onClose={closeModal} />}
                                                    {/********************************************************************************************************
                                                     * 
                                                     * Artist Profile 
                                                     * - Video tab
                                                     * 
                                                     *  */}
                                                    <TabPane tabId="1">
                                                        <div className='d-flex justify-content-end'>
                                                            <Button
                                                                color="primary"
                                                                className='right'
                                                                onClick={() => {
                                                                    tog_standard();
                                                                }}
                                                            >
                                                                New Video Upload
                                                            </Button>
                                                        </div>
                                                        <Modal id="myModal"
                                                            isOpen={modal_standard}
                                                            toggle={() => {
                                                                tog_standard();
                                                            }}
                                                        >
                                                            <ModalHeader className="modal-title"
                                                                id="myModalLabel" toggle={() => {
                                                                    tog_standard();
                                                                }}>
                                                                Video Auction Upload
                                                            </ModalHeader>
                                                            <ModalBody>
                                                                {loadingVideo ? (
                                                                    <p>Uploading...</p>
                                                                ) : (
                                                                    <form onSubmit={handleVideoFormSubmit}>
                                                                        <div className="form-group mt-4">
                                                                            <label htmlFor="title">Title of Art Piece</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="title"
                                                                                name="title"
                                                                                value={videoForm.title}
                                                                                onChange={handleVideoFormChange}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className="form-group mt-4">
                                                                            <label htmlFor="description">Description</label>
                                                                            <textarea
                                                                                className="form-control"
                                                                                id="description"
                                                                                name="description"
                                                                                value={videoForm.description}
                                                                                onChange={handleVideoFormChange}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group mt-4">
                                                                            <label htmlFor="image_url">Cover Image Upload</label>
                                                                            <input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="image_url"
                                                                                name="image_url"
                                                                                onChange={handleVideoFormChange}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className="form-group mt-4">
                                                                            <label htmlFor="video_url">Video Upload</label>
                                                                            <input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="video_url"
                                                                                name="video_url"
                                                                                onChange={handleVideoFormChange}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        {/* <div className="form-group mt-4">
                                                                            <label htmlFor="reserve_price">Reserve Price</label>
                                                                            <input
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="reserve_price"
                                                                                name="reserve_price"
                                                                                value={videoForm.reserve_price}
                                                                                onChange={handleVideoFormChange}
                                                                                required
                                                                            />
                                                                        </div> */}
                                                                        {/* <div className="form-group mt-4">
                                                                            <label htmlFor="shipping_cost">Shipping Cost</label>
                                                                            <input
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="shipping_cost"
                                                                                name="shipping_cost"
                                                                                value={videoForm.shipping_cost}
                                                                                onChange={handleVideoFormChange}
                                                                                required
                                                                            />
                                                                        </div> */}

                                                                        <div className="form-group mt-4">
                                                                            <label htmlFor="art_category">Art Category(Please select up to 3 different categories)</label>
                                                                            <select
                                                                                multiple
                                                                                className="form-control"
                                                                                id="art_category"
                                                                                name="art_category"
                                                                                value={videoForm.art_category}
                                                                                onChange={handleSelectFormChange}
                                                                                required
                                                                            >
                                                                                {[
                                                                                    "Painting",
                                                                                    "Drawing",
                                                                                    "Clay Sculpture",
                                                                                    "Wood Sculpture",
                                                                                    "Furniture",
                                                                                    "Watercolors",
                                                                                    "Paint Marker(s)",
                                                                                    "Pen Drawing",
                                                                                    "Pencil Drawing",
                                                                                    "Marker(s)",
                                                                                    "Graffiti",
                                                                                    "Animal(s)",
                                                                                    "Acrylic Paint",
                                                                                    "Pixel Art",
                                                                                    "Statue",
                                                                                    "Resin",
                                                                                    "Ceramic",
                                                                                    "Sculpture",
                                                                                    "Metalwork",
                                                                                    "Glasswork",
                                                                                    "Pottery",
                                                                                    "Stain glass"
                                                                                ].map((category) => (
                                                                                    <option key={category} value={category}>
                                                                                        {category}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                selectForm.map((item, index) => {
                                                                                    return <span key={index} className="badge bg-dark" style={{ marginRight: "5px" }} onClick={() => deleteSelectedForm(item)}>{item}  </span>
                                                                                })
                                                                            }
                                                                        </div>
                                                                        {/* Display selected categories */}
                                                                        {/* <div className="mt-4">
                                                                            <h6>Selected Categories:</h6>
                                                                            <ul>
                                                                                {videoForm.art_category.map((category) => (
                                                                                    <li key={category}>{category}</li>
                                                                                ))}
                                                                            </ul>
                                                                        </div> */}
                                                                        <br />
                                                                        <div className='d-flex justify-content-end'>

                                                                            <Button
                                                                                color="light"
                                                                                onClick={() => {
                                                                                    tog_standard();
                                                                                }}
                                                                            >
                                                                                Close
                                                                            </Button>
                                                                            <Button
                                                                                color="primary"
                                                                                type="submit"
                                                                            >
                                                                                Post
                                                                            </Button>
                                                                        </div>
                                                                    </form>
                                                                )}

                                                            </ModalBody>
                                                            <div className="modal-footer">
                                                            </div>
                                                        </Modal>


                                                        <Modal id="myAuction"
                                                            isOpen={modal_auction}
                                                            toggle={() => {
                                                                tog_auction(null);
                                                            }}
                                                        >
                                                            <ModalHeader className="modal-title"
                                                                id="myModalLabel" toggle={() => {
                                                                    tog_auction(null);
                                                                }}>
                                                                Auction
                                                            </ModalHeader>
                                                            <ModalBody>
                                                                <form onSubmit={handleAuctionFormSubmit}>
                                                                    <div className="mb-3">
                                                                        <img src={auctionForm.image_url} width="320">
                                                                        </img>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="start_datetime" className="form-label">
                                                                            Start Datetime
                                                                        </label>
                                                                        <input
                                                                            type="datetime-local"
                                                                            className="form-control"
                                                                            id="start_datetime"
                                                                            name="start_datetime"
                                                                            value={auctionForm.start_datetime}
                                                                            onChange={handleAuctionFormChange}
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="end_datetime" className="form-label">
                                                                            Auction Timeframe
                                                                        </label>
                                                                        <select

                                                                            id="auction_timeframe"
                                                                            name="auction_timeframe"
                                                                            value={auctionForm.auction_timeframe}
                                                                            onChange={handleAuctionFormChange}
                                                                            className="form-select mb-3" aria-label="auction timeframe">
                                                                            <option value="1">1 day</option>
                                                                            <option value="3">3 days</option>
                                                                            <option value="5">5 days</option>
                                                                        </select>
                                                                        {/* <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="auction_timeframe"
                                                                            name="auction_timeframe"
                                                                            value={auctionForm.auction_timeframe}
                                                                            onChange={handleAuctionFormChange}
                                                                            required
                                                                        /> */}
                                                                    </div>
                                                                    {/* <div className="mb-3">
                                                                        <label htmlFor="end_datetime" className="form-label">
                                                                            End Datetime
                                                                        </label>
                                                                        <input
                                                                            type="datetime-local"
                                                                            className="form-control"
                                                                            id="end_datetime"
                                                                            name="end_datetime"
                                                                            value={auctionForm.end_datetime}
                                                                            onChange={handleAuctionFormChange}
                                                                            required
                                                                        />
                                                                    </div> */}
                                                                    <div className="mb-3">
                                                                        <label htmlFor="reserve_price" className="form-label">
                                                                            Starting Price
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            step="0.01"
                                                                            className="form-control"
                                                                            id="reserve_price"
                                                                            name="reserve_price"
                                                                            value={auctionForm.reserve_price}
                                                                            onChange={handleAuctionFormChange}
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="shipping_cost" className="form-label">
                                                                            Est Shipping Cost
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            step="0.01"
                                                                            className="form-control"
                                                                            id="shipping_cost"
                                                                            name="shipping_cost"
                                                                            value={auctionForm.shipping_cost}
                                                                            onChange={handleAuctionFormChange}
                                                                            required
                                                                        />
                                                                    </div>

                                                                    <br />
                                                                    <div className='d-flex justify-content-end'>

                                                                        <Button
                                                                            className='mr-2'
                                                                            color="light"
                                                                            onClick={() => {
                                                                                tog_auction(null);
                                                                            }}
                                                                        >
                                                                            Close
                                                                        </Button>
                                                                        <Button
                                                                            color="primary"
                                                                            type="submit"
                                                                        >
                                                                            Post auction
                                                                        </Button>
                                                                    </div>
                                                                </form>
                                                            </ModalBody>
                                                            <div className="modal-footer">
                                                            </div>
                                                        </Modal>


                                                        <Modal id="myShekcout"
                                                            isOpen={modal_checkout}
                                                            toggle={() => {
                                                                tog_checkout(null);
                                                            }}
                                                        >
                                                            <ModalHeader className="modal-title"
                                                                id="myModalLabel" toggle={() => {
                                                                    tog_checkout(null);
                                                                }}>
                                                                Checkout
                                                            </ModalHeader>
                                                            <ModalBody>

                                                                <PayPalScriptProvider options={{ "client-id": "ATEtvglhbG3gh3WELGZqK4BB0BSW3exAdt3uwrOre6t1YCwkdTRqCSi82GxkCn7sQheG7-jM0xUbn6nu" }}>


                                                                    <form onSubmit={handleAuctionFormSubmit}>

                                                                        {checkoutFormData['bids'] && (
                                                                            <>
                                                                                <AuctionCard auction={checkoutFormData} ></AuctionCard>
                                                                                <div>
                                                                                    <div className="box-content" style={{ width: "100%" }} >
                                                                                        <div className="d-flex align-items-center justify-content-between ">
                                                                                            <div>
                                                                                                <div className="flex-grow-1 text-muted"><h6>Bid Amount:</h6></div>
                                                                                                <div className="flex-grow-1 text-muted"><h6>Shipping Cost:</h6></div>
                                                                                                <div className="flex-grow-1 text-muted"><h6>Total Price:</h6></div>

                                                                                            </div>
                                                                                            <div className="flex-shrink-1">
                                                                                                <div className="d-block gap-3">
                                                                                                    <button type="button" className="btn btn-sm fs-12 btn-link text-body text-decoration-none px-0">
                                                                                                        <i className="ri-price-tag-3-fill text-muted align-bottom me-1"></i> ${
                                                                                                            Math.max(...checkoutFormData['bids'].map(e => parseFloat(e['bid_amount'])))}
                                                                                                    </button>
                                                                                                    <br />
                                                                                                    <button type="button" className="btn btn-sm fs-12 btn-link text-body text-decoration-none px-0">
                                                                                                        <i className=" ri-shopping-cart-2-fill text-muted align-bottom me-1"></i> ${checkoutFormData['shipping_cost']}
                                                                                                    </button>
                                                                                                    <br />
                                                                                                    <button type="button" className="btn btn-sm fs-12 btn-link text-body text-decoration-none px-0">
                                                                                                        <i className=" ri-money-dollar-circle-fill text-muted align-bottom me-1"></i> ${parseFloat(checkoutFormData['shipping_cost']) + Math.max(...checkoutFormData['bids'].map(e => parseFloat(e['bid_amount'])))}
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </>
                                                                        )}



                                                                        <PayPalButtons
                                                                            createOrder={(data, actions) => {
                                                                                // Create an order and return the order ID
                                                                                return actions.order.create({
                                                                                    purchase_units: [
                                                                                        {
                                                                                            // breakdown: {
                                                                                            //     item_total: {
                                                                                            //         amount: {
                                                                                            //             currency_code: "USD",
                                                                                            //             value: "100.00", // Specify the payment amount
                                                                                            //         },
                                                                                            //     },
                                                                                            //     shipping: {
                                                                                            //         amount: {
                                                                                            //             currency_code: "USD",
                                                                                            //             value: "10.00", // Specify the payment amount
                                                                                            //         },
                                                                                            //     }
                                                                                            // },
                                                                                            amount: {
                                                                                                value: parseFloat(checkoutFormData['shipping_cost']) + Math.max(...checkoutFormData['bids'].map(e => parseFloat(e['bid_amount']))), // Specify the payment amount
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                });
                                                                            }}
                                                                            onApprove={(data, actions) => {
                                                                                // Handle a successful payment
                                                                                return actions.order.capture().then(function (details) {
                                                                                    createPayments({
                                                                                        auction_id: checkoutFormData['id'],
                                                                                        bid_id: checkoutFormData['bid_id'],
                                                                                        buyer_pay: JSON.stringify(details)
                                                                                    }).then(res => {
                                                                                        loadBids();
                                                                                    })
                                                                                    // Show a success message to the user
                                                                                });
                                                                            }}
                                                                        />

                                                                        <br />
                                                                        <div className='d-flex justify-content-end'>

                                                                            <Button
                                                                                className='mr-2'
                                                                                color="light"
                                                                                onClick={() => {
                                                                                    tog_checkout(null);
                                                                                }}
                                                                            >
                                                                                Close
                                                                            </Button>
                                                                            <Button
                                                                                color="primary"
                                                                                type="submit"
                                                                            >
                                                                                CheckOut
                                                                            </Button>
                                                                        </div>
                                                                    </form>
                                                                </PayPalScriptProvider>
                                                            </ModalBody>
                                                            <div className="modal-footer">
                                                            </div>
                                                        </Modal>

                                                        <div className="table-responsive">
                                                            <Table className="table-borderless table-responsive mb-0">
                                                                <thead>
                                                                    <tr>

                                                                        <th style={{ width: '4%' }}>ID#</th>
                                                                        <th style={{ width: '8%' }}>Title of Art</th>
                                                                        <th style={{ width: '20%' }}>Video</th>
                                                                        <th style={{ width: '' }}>Description</th>
                                                                        <th style={{ width: '5%' }}>Duration</th>
                                                                        <th style={{ width: '12%' }}>Uploaded On</th>
                                                                        <th style={{ width: '10%' }}>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {videos.map((video, index) => (
                                                                        <tr key={index}>
                                                                            <td style={{ width: '4%' }} className="ps-0" scope="row">{video.id}</td>
                                                                            <td style={{ width: '8%' }} className="text-muted">
                                                                                <div className="mt-4" onClick={() => { }}
                                                                                >
                                                                                    <h5>{video.title}</h5>
                                                                                    <img src={JSON.parse(video.video_url)[0]} width="180px" height="90px" style={{ borderRadius: "5px" }}></img>
                                                                                </div>
                                                                            </td>
                                                                            <td style={{ width: '20%' }}>
                                                                                <video muted controls style={{ width: '200px', height: '180px' }}>
                                                                                    <source src={JSON.parse(video.video_url)[1]} type="video/mp4" />
                                                                                    Your browser does not support the video tag.
                                                                                </video>
                                                                            </td>
                                                                            <td style={{ width: '' }} className="text-muted text-ellipsis">{video.description}</td>
                                                                            <td style={{ width: '5%' }} className="text-muted ">{video.duration}</td>
                                                                            <td style={{ width: '12%' }} className="text-muted">{video.createdAt}</td>
                                                                            <td style={{ width: '10%' }} className="text-muted">
                                                                                <ButtonGroup>
                                                                                    <UncontrolledDropdown>
                                                                                        <DropdownToggle tag="button" className="btn btn-primary">
                                                                                            <i className="mdi mdi-chevron-down"></i>
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem onClick={() => { editVideo(video) }}>Edit</DropdownItem>
                                                                                            <DropdownItem onClick={() => { handleDeleteVideo(video) }}>Delete</DropdownItem>
                                                                                            {video.auctions.length == 0 && (
                                                                                                <>
                                                                                                    <DropdownItem divider />
                                                                                                    <DropdownItem onClick={() => {
                                                                                                        // Remove the limit check
                                                                                                        // Just call the 'tog_auction' function without checking active auctions
                                                                                                        tog_auction(video)
                                                                                                    }}>
                                                                                                        Post
                                                                                                    </DropdownItem>

                                                                                                </>
                                                                                            )}
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </ButtonGroup>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>


                                                    </TabPane>

                                                    {/********************************************************************************************************
                                                     * 
                                                     * Artist Profile 
                                                     * - Auction
                                                     * 
                                                     *  */}
                                                    <TabPane tabId="2">
                                                        <Table className="table-borderless table-responsive mb-0">
                                                            <thead>
                                                                <tr>

                                                                    <th style={{ width: '4%' }}>ID#</th>
                                                                    <th style={{ width: '20%' }}>Auction Video</th>
                                                                    <th style={{ width: '5%' }}>Winning Bid</th>
                                                                    <th style={{ width: '5%' }}>Auction Start Date</th>
                                                                    <th style={{ width: '5%' }}>Auction End Date</th>
                                                                    <th style={{ width: '5%' }}>Reserve Price</th>
                                                                    <th style={{ width: '5%' }}>Shipping Cost</th>
                                                                    <th style={{ width: '5%' }}>Tracking Number</th>
                                                                    <th style={{ width: '10%' }}>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {auctions.map((auction, index) => (
                                                                    <tr key={index}>
                                                                        <td style={{ width: '4%' }} className="ps-0" scope="row">{auction.id}</td>
                                                                        <td style={{ width: '20%' }}>
                                                                            <div className=''>
                                                                                {/* <div onClick={() => { }}
                                                                                >
                                                                                    <img src={JSON.parse(auction.video.video_url)[0]} width="240px" height="180px"></img>
                                                                                </div> */}
                                                                                <div className="video-container">
                                                                                    <video muted controls style={{ width: '240px', height: '180px' }}>
                                                                                        <source src={JSON.parse(auction.video.video_url)[1]} type="video/mp4" />
                                                                                        Your browser does not support the video tag.
                                                                                    </video>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td style={{ width: '10%' }} className="text-muted">
                                                                            {auction.bids.length > 0 && (
                                                                                <h5>
                                                                                    <span className="badge rounded-pill bg-primary">
                                                                                        {auction.bids.sort((b, a) => a.bid_amount - b.bid_amount)[0]['bid_amount']
                                                                                            //  + " of " + auction.bids.length + " bidders"
                                                                                        }
                                                                                    </span>
                                                                                </h5>
                                                                            )}

                                                                            {!auction.bids.length > 0 && (
                                                                                <h5>
                                                                                    <span className="badge rounded-pill bg-danger">
                                                                                        Never Bid
                                                                                    </span>
                                                                                </h5>
                                                                            )}

                                                                        </td>
                                                                        <td style={{ width: '10%' }} className="text-muted">{auction.start_datetime}</td>
                                                                        <td style={{ width: '10%' }} className="text-muted">{auction.end_datetime}</td>
                                                                        <td style={{ width: '10%' }} className="text-muted">{auction.reserve_price}</td>
                                                                        <td style={{ width: '10%' }} className="text-muted">{auction.shipping_cost}</td>
                                                                        <td style={{ width: '10%' }} className="text-muted">{auction.tracking_number}</td>
                                                                        <td style={{ width: '10%' }} className="text-muted">
                                                                            <ButtonGroup>
                                                                                <UncontrolledDropdown>
                                                                                    <DropdownToggle tag="button" className="btn btn-primary">
                                                                                        <i className="mdi mdi-chevron-down"></i>
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { handleDeleteAuction(auction) }}>Delete</DropdownItem>
                                                                                        {/* <DropdownItem onClick={() => { tog_auction(row) }}>Create Auction</DropdownItem> */}
                                                                                        <DropdownItem onClick={() => handleAddTrackingNumber(auction)}>Add Tracking Number</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </ButtonGroup>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </TabPane>
                                                </TabContent>

                                            </CardBody>
                                        </Card>
                                    </TabPane>

                                    <TabPane tabId="3">
                                        <Card>
                                            <CardHeader>

                                                <h5 className="card-title mb-3">Buyer profiles</h5>

                                                {/* <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                                    role="tablist">
                                                    <NavItem>
                                                        <NavLink
                                                            to="#"
                                                            className={classnames({ active: activeBuyerTab === "1" })}
                                                            onClick={() => {
                                                                buyerTabChange("1");
                                                            }}
                                                            type="button"
                                                        >
                                                            <i className="fas fa-home"></i>
                                                            Bids
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink to="#"
                                                            className={classnames({ active: activeBuyerTab === "2" })}
                                                            onClick={() => {
                                                                buyerTabChange("2");
                                                            }}
                                                            type="button">
                                                            <i className="far fa-user"></i>
                                                            Auctions
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav> */}
                                            </CardHeader>
                                            <CardBody>
                                                <Table className="table-borderless table-responsive mb-0">
                                                    <thead>
                                                        <tr>

                                                            <th style={{ width: '4%' }}>ID#</th>
                                                            <th style={{ width: '20%' }}>Auction</th>
                                                            <th style={{ width: '5%' }}>Bid Amount</th>
                                                            <th style={{ width: '5%' }}>Status</th>
                                                            <th style={{ width: '5%' }}>Bidders</th>
                                                            <th style={{ width: '20%' }}>Start & End Time</th>
                                                            <th style={{ width: '10%' }}>Tracking Number</th>
                                                            <th style={{ width: '10%' }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {bids.map((bid, index) => (
                                                            <tr key={index}>
                                                                <td style={{ width: '4%' }} className="ps-0" scope="row">{bid.id}</td>
                                                                <td style={{ width: '20%' }}>
                                                                    {/* {bid.auction.video.title} */}
                                                                    {/* <div onClick={() => { }}
                                                                    >
                                                                        <img src={JSON.parse(bid.auction.video.video_url)[0]} width="180px" height="120px"></img>
                                                                    </div> */}
                                                                    <div className='center' >
                                                                        <AuctionSimpleCard auction={bid.auction}></AuctionSimpleCard>
                                                                    </div>
                                                                </td>
                                                                <td style={{ width: '10%' }} className="text-muted">{bid.bid_amount}</td>
                                                                <td style={{ width: '10%' }} className="text-muted">
                                                                    {bid.auction.bids.sort((b, a) => a.bid_amount - b.bid_amount)[0]['id'] == bid.id && (
                                                                        <h5><span className="badge rounded-pill bg-danger">{new Date(bid.auction.end_datetime) > new Date() ? "Winning" : "Won"}</span></h5>
                                                                    )}
                                                                    <br />
                                                                    {(bid.payment && JSON.parse(bid.payment['buyer_pay'])['status'] == 'COMPLETED') && (
                                                                        <h5><span className="badge rounded-pill bg-success">Paid</span></h5>
                                                                    )}
                                                                </td>
                                                                <td style={{ width: '10%' }} className="text-muted">{bid.bidders}</td>
                                                                <td style={{ width: '20%' }} className="text-muted">{convertDateTime(bid.auction.start_datetime).toLocaleString()} - {convertDateTime(bid.auction.end_datetime).toLocaleString()}</td>
                                                                <td style={{ width: '10%' }} className="text-muted">
                                                                    {new Date(bid.auction.end_datetime) < new Date() && bid.auction.bids.sort((b, a) => a.bid_amount - b.bid_amount)[0]['id'] !== bid.id ? bid.auction.tracking_number : null}
                                                                </td>
                                                                <td style={{ width: '10%' }} className="text-muted">
                                                                    <ButtonGroup>
                                                                        <UncontrolledDropdown>
                                                                            <DropdownToggle tag="button" className="btn btn-primary">
                                                                                <i className="mdi mdi-chevron-down"></i>
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                {bid.payment && (
                                                                                    <>
                                                                                        <DropdownItem onClick={() => { downloadVideo(bid.auction.video) }}>
                                                                                            Download
                                                                                        </DropdownItem>
                                                                                    </>
                                                                                )
                                                                                }

                                                                                {/* {bid.auction.bids.sort((b, a) => a.bid_amount - b.bid_amount)[0]['id'] == bid.id && (
                                                                                    <h5><span className="badge rounded-pill bg-danger">{ ? "Winning" : "Won"}</span></h5>
                                                                                )} */}

                                                                                {(!bid.payment && bid.auction.bids.sort((b, a) => a.bid_amount - b.bid_amount)[0]['id'] == bid.id && (new Date(bid.auction.end_datetime) < new Date())) && (
                                                                                    <>
                                                                                        <DropdownItem onClick={() => { tog_checkout(bid) }}>
                                                                                            Buy It Now
                                                                                        </DropdownItem>
                                                                                        <DropdownItem divider />
                                                                                    </>
                                                                                )
                                                                                }
                                                                                <DropdownItem onClick={() => { handleBuyAuction(bid) }}>Cancel Bid</DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </ButtonGroup>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>

                                            </CardBody>
                                        </Card>
                                    </TabPane>

                                    {/* <TabPane tabId="4">
                                        <Card>
                                            <CardBody>
                                                <div className="d-flex align-items-center mb-4">
                                                    <h5 className="card-title flex-grow-1 mb-0">Payment Settings</h5>
                                                    <div className="flex-shrink-0">
                                                        <Input className="form-control d-none" type="file" id="formFile" />
                                                        <Label htmlFor="formFile" className="btn btn-danger"><i className="ri-upload-2-fill me-1 align-bottom"></i> Upload
                                                            File</Label>
                                                    </div>
                                                </div>

                                            </CardBody>
                                        </Card>
                                    </TabPane> */}
                                </TabContent>
                            </div>
                        </Col>
                    </Row >

                </Container >
            </div >
        </React.Fragment >
    );
};

export default SimplePage;