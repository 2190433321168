import React, { useState, useEffect, Component, useRef } from 'react';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import playIcon from "../../assets/images/users/play_icon1.png";
import { getLoggedInUser, getMaxBidAmount } from '../../helpers/fakebackend_helper';
const AuctionCard = (props) => {
    const user = getLoggedInUser();

    const videoRef = useRef(null);

    const [isPlaying, setIsPlaying] = useState(false);
    const [isDescriptionVisible, setDescriptionVisible] = useState(false);

    const toggleDescription = () => {
        setDescriptionVisible(!isDescriptionVisible);
    };

    const auction = props.auction;
    const startDateTime = new Date(auction['start_datetime']);
    const endDateTime = new Date(auction['end_datetime']);
    // console.log(`Auction ends on: ${endDateTime}`);
    const nowDateTime = new Date();
    const startformattedDate = startDateTime.toLocaleDateString(); // Format as "mm/dd/yyyy"
    const startformattedTime = startDateTime.toLocaleTimeString(); // Format as "hh:mm:ss AM/PM"
    const endformattedDate = endDateTime.toLocaleDateString();
    const endformattedTime = endDateTime.toLocaleTimeString();
    const endformattedDateTime = `${endformattedDate} ${endformattedTime}`;
    // console.log(`Auction ends on: ${endformattedDate} at ${endformattedTime}`);
    // const endformattedDate = endDateTime.toLocalDateStrong(); //Format for end as "mm/dd/yyyy"
    // const endformattedTime = endDateTime.toLocaltimeStrong(); //Format for end as "hh:mm:ss AM/PM"
    // const endformattedDateTime = `${endformattedDate} ${endformattedTime}`;
    const startformattedDateTime = `${startformattedDate} ${startformattedTime}`;
    // console.log(`Auction starts on: ${startformattedDate} at ${startformattedTime}`);

    useEffect(() => {
        var nowTime = new Date();
        var startTime = new Date(auction['start_datetime']);
        var endTime = new Date(auction['end_datetime']);
        if (nowTime > startTime && nowTime < endTime) {
            const video = videoRef.current;
            if (user && video) {
                if (isPlaying) {
                    video.pause();
                } else {
                    video.play();
                }
                setIsPlaying(!isPlaying);
            }
        }
    }, []);

    const handleVideoEnded = () => {
        // Pause the video
        videoRef.current.pause();

        // Restart the video
        videoRef.current.currentTime = 0;
        videoRef.current.play();
        setIsPlaying(false);
    };
    return (
        <React.Fragment>
            {auction.video && (

                <div className="gallery-box card">

                    <div className="gallery-container   " style={{ width: "100%" }}>
                        <h5 style={{ textAlign: 'center' }}>{auction.video.title}</h5>
                        <div className="image-popup text-center" title="" onClick={(e) => {
                            e.preventDefault()
                            props.openBid(auction)
                        }}>
                            <div className='ribbon-box right overflow-hidden card'>
                                {/* {!user && (
                                    <>
                                        <div style={{ position: "relative" }} >
                                            <img className="gallery-img img-fluid mx-auto" src={JSON.parse(auction.video.video_url)[0]} alt="" style={{ width: "600px", height: "320px", objectFit: "cover" }} />
                                            <div className="gallery-overlay">
                                                <h5 className="overlay-caption">{auction.video['title']}</h5>
                                            </div>
                                        </div>

                                        {(startDateTime < nowDateTime && endDateTime > nowDateTime) && (
                                            <div style={{ cursor: "pointer", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                                <img src={playIcon} alt="" width="50px"
                                                // style={{ animation: "mover 1s infinite alternate" }} 
                                                />
                                            </div>

                                        )}
                                    </>
                                )} */}

                                {/* {user && ( */}
                                    <>
                                        <video muted autoPlay={true}
                                            ref={videoRef}
                                            onEnded={handleVideoEnded}
                                        // width="640" // Set the video width
                                        // height="360" // Set the video height
                                        // onClick={togglePlay}
                                        >
                                            <source
                                                src={JSON.parse(auction.video.video_url)[1]}  // Provide the path to your video file
                                                type="video/mp4"
                                            />
                                            Your browser does not support the video tag.
                                        </video>
                                    </>
                                {/* // )} */}



                                {
                                    startDateTime > nowDateTime && (
                                        <div className="ribbon ribbon-info ribbon-shape trending-ribbon card">
                                            <i className="ri-flashlight-fill text-white align-bottom"></i>{" "}
                                            <span className="trending-ribbon-text">Upcomming</span>
                                        </div>
                                    )
                                }
                                {
                                    endDateTime < nowDateTime && (
                                        <div className="ribbon ribbon-primary ribbon-shape trending-ribbon card">
                                            <i className="ri-flashlight-fill text-white align-bottom"></i>{" "}
                                            <span className="trending-ribbon-text">Completed</span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="box-content" style={{ width: "100%" }} >
                        <div className="d-flex align-items-center justify-content-between mt-1">
                            <div>
                                <div className="flex-grow-1 text-muted"><h6> Started on {startformattedDateTime} <br /> Ends {endformattedDateTime}</h6></div>
                                <div className="flex-grow-1 text-muted">by <div to="" className="text-body text-truncate">{auction.user ? auction.user['firstName'] : ""}</div></div>

                            </div>
                            <div className="flex-shrink-1">
                                <div className="d-block gap-3">
                                    <button type="button" className="btn btn-sm fs-12 btn-link text-body text-decoration-none px-0">
                                        <i className="ri-price-tag-3-fill text-muted align-bottom me-1"></i> ${getMaxBidAmount(auction)}
                                    </button>
                                    <br />
                                    <button type="button" className="btn btn-sm fs-12 btn-link text-body text-decoration-none px-0">
                                        <i className=" ri-shopping-cart-2-fill text-muted align-bottom me-1"></i> ${auction.shipping_cost}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex-grow-1 text-muted" style={{ cursor: "pointer" }} onClick={toggleDescription}>
                            Descripion
                        </div>
                        <div style={{ display: isDescriptionVisible ? 'block' : 'none' }}>
                            <h5>{auction.video.description}</h5>
                        </div>
                    </div>
                </div>

            )}
        </React.Fragment>
    );
};

export default AuctionCard;