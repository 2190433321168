import React from 'react';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import playIcon from "../../assets/images/users/play_icon1.png";
import { getMaxBidAmount } from '../../helpers/fakebackend_helper';

const AuctionSimpleCard = (props) => {
    const auction = props.auction;
    const startDateTime = new Date(auction['start_datetime']);
    const endDateTime = new Date(auction['end_datetime']);
    const nowDateTime = new Date();
    const formattedDate = startDateTime.toLocaleDateString(); // Format as "mm/dd/yyyy"
    const formattedTime = startDateTime.toLocaleTimeString(); // Format as "hh:mm:ss AM/PM"
    const formattedDateTime = `${formattedDate} ${formattedTime}`;


    return (
        <React.Fragment>
            {auction.video && (

                <div className="gallery-box card">

                    <div className="gallery-container   " style={{ width: "100%" }}>
                        <div className="image-popup text-center" title="" onClick={(e) => {
                            e.preventDefault()
                        }}>
                            <div className='ribbon-box right overflow-hidden card'>
                                <div style={{ position: "relative" }} >
                                    <img className="gallery-img img-fluid mx-auto" src={JSON.parse(auction.video.video_url)[0]} alt="" style={{ width: "240px", height: "120px", objectFit: "cover" }} />
                                    <div className="gallery-overlay">
                                        <h5 className="overlay-caption">{auction.video['title']}</h5>
                                    </div>
                                </div>

                                {/* {(startDateTime < nowDateTime && endDateTime > nowDateTime) && (
                                    <div style={{ cursor: "pointer", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                        <img src={playIcon} alt="" width="50px"
                                        />
                                    </div>
                                )} */}

                                {
                                    startDateTime > nowDateTime && (
                                        <div className="ribbon ribbon-info ribbon-shape trending-ribbon card">
                                            <i className="ri-flashlight-fill text-white align-bottom"></i>{" "}
                                            <span className="trending-ribbon-text">Upcomming</span>
                                        </div>
                                    )
                                }
                                {
                                    endDateTime < nowDateTime && (
                                        <div className="ribbon ribbon-primary ribbon-shape trending-ribbon card">
                                            <i className="ri-flashlight-fill text-white align-bottom"></i>{" "}
                                            <span className="trending-ribbon-text">Completed</span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>

            )}
        </React.Fragment>
    );
};

export default AuctionSimpleCard;