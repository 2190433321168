import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Tooltip } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

//import images 
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import PasswordStrengthBar from 'react-password-strength-bar';

const Register = (props) => {
    const dispatch = useDispatch();
    const [showpwd, setshowpwd] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState({
        generate: false,
        toggle: false
    });
    const toggleTooltip = (type) => {
        setTooltipOpen({
            ...tooltipOpen,
            [type]: !tooltipOpen[type]
        });
    };

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
            // username: '',
            password: '',
            firstName: '',
            lastName: '',
            // verifyPassword: '',
            phone: '',
            zipcode: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            // username: Yup.string().required("Please Enter Your Username"),
            password: Yup.string().required("Please Enter Your Password"),
            lastName: Yup.string().required("Please Enter Your lastName"),
            // verifyPassword: Yup.string().required("Please Enter Your verifyPassword"),
            phoneNumber: Yup.string().required("Please Enter Your phoneNumber"),
            firstName: Yup.string().required("Please Enter Your firstName"),
        }),
        onSubmit: (values) => {
            dispatch(registerUser(values, props.history));
        }
    });

    const { user, registrationError } = useSelector(state => ({
        user: state.Account.user,
        registrationError: state.Account.registrationError,
    }));

    const [termsChecked, setTermsChecked] = useState(false);
    const handleTermsCheckboxChange = () => {
        setTermsChecked(!termsChecked);
    };

    const generatePassword = () => {
        const length = 12; // Set the desired length of the password

        // Define the characters that can be used in the password
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';

        let password = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            password += characters.charAt(randomIndex);
        }

        return password;
    };


    useEffect(() => {
        dispatch(apiError(""));
    }, [dispatch]);

    document.title = "Basic SignUp | Artsoot";

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/home" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="30" />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Sign up to collect art by the world’s leading artists</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">

                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Create New Account</h5>
                                            <p className="text-muted">Get your artsoot account now</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                className="needs-validation" action="#">
                                                {user && user ? (
                                                    <Alert color="success">
                                                        Register User Successfully
                                                    </Alert>
                                                ) : null}

                                                {registrationError && registrationError ? (
                                                    <Alert color="danger"><div>{registrationError}</div></Alert>
                                                ) : null}

                                                <div className="mb-3">
                                                    <Label htmlFor="firstName" className="form-label">First Name<span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="firstName"
                                                        className="form-control"
                                                        placeholder="Enter First Name"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.firstName || ""}
                                                        invalid={
                                                            validation.touched.firstName && validation.errors.firstName ? true : false
                                                        }
                                                        required
                                                    />
                                                    {validation.touched.firstName && validation.errors.firstName ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.firstName}</div></FormFeedback>
                                                    ) : null}
                                                    {/* <div className="invalid-feedback">
                                                        Please enter First Name
                                                    </div> */}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="lastName" className="form-label">Last Name<span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="lastName"
                                                        className="form-control"
                                                        placeholder="Enter Last Name"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.lastName || ""}
                                                        invalid={
                                                            validation.touched.lastName && validation.errors.lastName ? true : false
                                                        }
                                                        required
                                                    />
                                                    {validation.touched.lastName && validation.errors.lastName ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.lastName}</div></FormFeedback>
                                                    ) : null}
                                                    {/* <div className="invalid-feedback">
                                                        Please enter First Name
                                                    </div> */}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="useremail" className="form-label">Email <span className="text-danger">*</span></Label>
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email address"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                        required
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                                    ) : null}
                                                    {/* <div className="invalid-feedback">
                                                        Please enter email
                                                    </div> */}
                                                </div>
                                                {/* <div className="mb-3">
                                                    <Label htmlFor="username" className="form-label">Username <span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="username"
                                                        type="text"
                                                        placeholder="Enter username"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.username || ""}
                                                        invalid={
                                                            validation.touched.username && validation.errors.username ? true : false
                                                        }
                                                    />
                                                    {validation.touched.username && validation.errors.username ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.username}</div></FormFeedback>
                                                    ) : null}
                                                    <div className="invalid-feedback">
                                                        Please enter username
                                                    </div>
                                                </div> */}

                                                <div className="mb-2">
                                                    <Label htmlFor="userpassword" className="form-label">Password <span className="text-danger">*</span></Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            type={showpwd ? "text" : "password"}
                                                            placeholder="Enter Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.password || ""}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                            required
                                                        />
                                                        <button
                                                            className="btn btn-link position-absolute end-0 text-decoration-none text-muted"
                                                            type="button"
                                                            id="password-addon"
                                                            style={{ marginRight: "10px", top: "8px", padding: 0 }}
                                                            onClick={() => { setshowpwd(!showpwd); }}
                                                            onMouseEnter={() => toggleTooltip('toggle')}
                                                            onMouseLeave={() => toggleTooltip('toggle')}
                                                        >
                                                            <i className="ri-eye-fill align-middle"></i>
                                                        </button>
                                                        <Tooltip isOpen={tooltipOpen.toggle} target="password-addon" toggle={() => toggleTooltip('toggle')}>
                                                            {showpwd ? "Hide Password" : "Show Password"}
                                                        </Tooltip>

                                                        <button
                                                            className="btn btn-link position-absolute text-decoration-none text-muted"
                                                            type="button"
                                                            id="generate-password-addon"
                                                            style={{ right: "28px", top: "8px", padding: 0 }}
                                                            onClick={() => {
                                                                const newPassword = generatePassword();
                                                                validation.setFieldValue("password", newPassword);
                                                            }}
                                                            onMouseEnter={() => toggleTooltip('generate')}
                                                            onMouseLeave={() => toggleTooltip('generate')}
                                                        >
                                                            <i className="ri-shield-keyhole-line align-middle"></i>
                                                        </button>
                                                        <Tooltip isOpen={tooltipOpen.generate} target="generate-password-addon" toggle={() => toggleTooltip('generate')}>
                                                            Generate a random password
                                                        </Tooltip>

                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid"><div>{validation.errors.password}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <PasswordStrengthBar password={validation.values.password} />
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="phoneNumber" className="form-label">Phone Number <span className="text-danger">*</span></Label>
                                                    <Input
                                                        id="phoneNumber"
                                                        name="phoneNumber"
                                                        className="form-control"
                                                        placeholder="Enter Phone Number"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.phoneNumber || ""}
                                                        invalid={
                                                            validation.touched.phoneNumber && validation.errors.phoneNumber ? true : false
                                                        }
                                                        required
                                                    />
                                                    {validation.touched.phoneNumber && validation.errors.phoneNumber ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.phoneNumber}</div></FormFeedback>
                                                    ) : null}
                                                    {/* <div className="invalid-feedback">
                                                        Please enter phoneNumber
                                                    </div> */}
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="zipcode" className="form-label">Zip Code</Label>
                                                    <Input
                                                        id="zipcode"
                                                        name="zipcode"
                                                        className="form-control"
                                                        placeholder="Enter Zip Code"
                                                        type="varchar"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.zipcode || ""}
                                                        invalid={
                                                            validation.touched.zipcode && validation.errors.zipcode ? true : false
                                                        }
                                                    />
                                                    {validation.touched.zipcode && validation.errors.zipcode ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.zipcode}</div></FormFeedback>
                                                    ) : null}
                                                    {/* <div className="invalid-feedback">
                                                        Please enter phoneNumber
                                                    </div> */}
                                                </div>

                                                <div className="mb-4">
                                                    <input
                                                        type="checkbox"
                                                        className="m-2"
                                                        name="terms"
                                                        checked={termsChecked}
                                                        onChange={handleTermsCheckboxChange}
                                                    />
                                                    <span className="mb-0 fs-12 text-muted fst-italic">I Agree to the &nbsp;
                                                        <Link to="#" className="text-primary text-decoration-underline fst-normal fw-medium">Terms of Use</Link></span>
                                                </div>

                                                <div className="mt-4">
                                                    <button className="btn btn-success w-100" type="submit" disabled={!termsChecked}>Sign Up</button>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <div className="signin-other-title">
                                                        <h5 className="fs-13 mb-4 title text-muted">Create account with</h5>
                                                    </div>

                                                    <div>
                                                        {/* <button type="button" className="btn btn-primary btn-icon waves-effect waves-light"><i className="ri-facebook-fill fs-16"></i></button>{" "}
                                                        <button type="button" className="btn btn-danger btn-icon waves-effect waves-light"><i className="ri-google-fill fs-16"></i></button>{" "} */}
                                                        {/* <button type="button" className="btn btn-dark btn-icon waves-effect waves-light"><i className="ri-github-fill fs-16"></i></button>{" "}
                                                        <button type="button" className="btn btn-info btn-icon waves-effect waves-light"><i className="ri-twitter-fill fs-16"></i></button> */}
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="mt-4 text-center">
                                    <p className="mb-0">Already have an account ? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Sign In </Link> </p>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default Register;
