/* eslint-disable import/first */
import React, { useState, useEffect, Component } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useHistory } from 'react-router-dom'

//import images
import avatar1 from "../../assets/images/users/user-dummy-img.jpg";
import { getLoggedInUser, getUserDetail, isUserAuthenticated } from '../../helpers/fakebackend_helper';


const ProfileDropdown = () => {
    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const history = useHistory()

    const user = getLoggedInUser();

    const [userDetail, setUserDetail] = useState({
        "firstName": "",
        "lastName": "",
        "email": "",
        "phone": "",
        "info": {
            paymentAddress: "",
            country: "",
            city: "",
            // zipCode: "",
            description: "",
            avatar: ""
        },
    });
    const adminEmail = "alex.donaghue@gmail.com";

    useEffect(() => {

        getUserDetail().then(res => {
            if (res) {
                if (!res['info']) {
                    res['info'] = userDetail.info;
                } else {
                    res['info'] = { ...userDetail.info, ...JSON.parse(res['info']) };
                }
                setUserDetail(res);
            }
        })


        return () => {
        }
    }, [])

    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={userDetail.info.avatar ? userDetail.info.avatar : avatar1}
                            alt="Header Avatar" />
                        <span className="text-start ms-xl-2">
                            <h6>
                                <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{user ? userDetail.firstName + " " + userDetail.lastName : ""}</span>
                            </h6>
                            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text"></span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">

                    <h6 className="dropdown-header">Welcome!</h6>
                    <DropdownItem onClick={() => history.push('/pages-profile')}>
                        <i className="mdi mdi-account-outline text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle">Profile</span>
                    </DropdownItem>
                    {userDetail.email === adminEmail && (
                        <DropdownItem onClick={() => history.push('/admin-videos-list')}>
                            <i className="mdi mdi-video text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">All Video</span>
                        </DropdownItem>
                    )}
                    {userDetail.email === adminEmail && (
                        <DropdownItem onClick={() => history.push('/auction-management')}>
                            <i className="mdi mdi-gavel text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Auction Management</span>
                        </DropdownItem>
                    )}

                    {/* <DropdownItem href="/apps-chat"><i
                        className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Messages</span></DropdownItem> */}
                    {/* <DropdownItem href="/apps-tasks-kanban"><i
                        className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Taskboard</span></DropdownItem> */}
                    {/* <DropdownItem href="/pages-faqs"><i
                        className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Help</span></DropdownItem> */}
                    <div className="dropdown-divider"></div>
                    {/* <DropdownItem onClick={() => history.push("/pages-profile")}><i
                        className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Balance : <b>$5971.67</b></span></DropdownItem> */}
                    <DropdownItem onClick={() => history.push("/pages-profile-settings")}><i
                        className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Settings</span></DropdownItem>
                    {/* <DropdownItem onClick={() => history.push("/auth-lockscreen-basic")}><i
                        className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Lock screen</span></DropdownItem> */}
                    <DropdownItem onClick={() => history.push("/logout")} ><i
                        className="mdi mdi-logout text-muted fs-16 align-middlef me-1"></i> <span
                            className="align-middle" data-key="t-logout">Logout</span></DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;