import React from 'react';

import Navbar from './navbar';
import Home from './home';
import Client from './client';
import Services from './services';
import Features from './features';
import Plans from './plans';
import Faqs from './faq';
import Reviews from './reviews';
import Counter from './counter';
import WorkProcess from './workProcess';
import Team from './team';
import Contact from './contact';
import Cta from './cta';
import { getLoggedInUser } from '../../helpers/fakebackend_helper';
// import Footer from '../../Layouts/VerticalLayouts/Footer';
import Footer from './footer';

const Index = () => {
    document.title = "Artsoot - Video Art Auction";
    const user = getLoggedInUser();
    return (
        <React.Fragment>
            <div className="layout-wrapper landing">
                {!user && (<Navbar />)}
                <Home />
                {/* <Client /> */}
                {/* <Services /> */}
                {/* <Features /> */}
                {/* <Plans /> */}

                {/* <Faqs /> */}
                {/* <Reviews /> */}
                {/* <Counter /> */}

                {/* <WorkProcess /> */}
                {/* <Team /> */}


                {/* <Contact /> */}
                {!user && (<Footer />)}
            </div>
        </React.Fragment>
    );
};

export default Index;