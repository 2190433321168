module.exports = {
  // API_URL: "https://ec2-54-87-107-72.compute-1.amazonaws.com",
  // API_URL: "https://7lkmtcf8w3.execute-api.ap-southeast-2.amazonaws.com/production",
  // API_URL: "https://6iwbdpqlll.execute-api.us-east-1.amazonaws.com/production",
  // API_URL: "https://ec2-54-87-107-72.compute-1.amazonaws.com",
  
  API_URL: "https://artsoot.com:4443",
  google: {
    API_KEY: "AIzaSyCA3KeQ1NJr0QrryN0OC2NIIF40LpVpFac",
    CLIENT_ID: "223410460412-fmgnmp9ld4ebvf5gqhcl6p196riqt83d.apps.googleusercontent.com",
    SECRET: "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",
  },
  facebook: {
    APP_ID: "",
  },
}