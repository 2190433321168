import React, { useState, useEffect, Component, useRef } from 'react';
import {
    Modal,
    ModalHeader,
    Button,
    ModalBody,
    ButtonGroup,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Input
} from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';
import playIcon from "../../assets/images/users/play_icon1.png";
import { createBid, getLoggedInUser, getMaxBidAmount } from '../../helpers/fakebackend_helper';
import Swal from 'sweetalert2'

const AuctionDetailModal = (props) => {
    const history = useHistory();

    const user = getLoggedInUser();

    const [isPlaying, setIsPlaying] = useState(true);
    const videoRef = useRef(null);

    const auction = props.auction;
    const inputDate = new Date(auction['start_datetime']);
    const formattedDate = inputDate.toLocaleDateString(); // Format as "mm/dd/yyyy"
    const formattedTime = inputDate.toLocaleTimeString(); // Format as "hh:mm:ss AM/PM"
    const formattedDateTime = `${formattedDate} ${formattedTime}`;

    const [modal_standard, setmodal_standard] = useState(true);
    const [bidAmount, setBidAmount] = useState(0);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        setmodal_standard(props.open)
    }, []);

    useEffect(() => {
        setBidAmount(getMaxBidAmount(auction) + 1);
    }, [props.auction]);


    const handleVideoEnded = () => {
        // Pause the video
        videoRef.current.pause();

        // Restart the video
        videoRef.current.currentTime = 0;
        videoRef.current.play();
        setIsPlaying(false);
    };

    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    const togglePlay = () => {
        var nowTime = new Date();
        var startTime = new Date(auction['start_datetime']);
        var endTime = new Date(auction['end_datetime']);
        if (nowTime > startTime && nowTime < endTime) {
            if (user) {
                const video = videoRef.current;
                if (isPlaying) {
                    video.pause();
                } else {
                    video.play();
                }
                setIsPlaying(!isPlaying);
            }
        }
    };

    return (
        <Modal id="myModal"
            isOpen={props.open}
            toggle={() => {
                props.close();
            }}
            size="lg"
        >
            <ModalHeader className="modal-title"
                id="myModalLabel" toggle={() => {
                    props.close()
                }}>
                {auction.video ? auction.video['title'] : ""}
            </ModalHeader>
            <ModalBody >
                {
                    auction['video_id'] && (
                        <div className="gallery-box card">

                            <div className="gallery-container" style={{ width: "100%" }}>
                                <div className="image-popup text-center" title="" onClick={(e) => {
                                    e.preventDefault()
                                }}>
                                    <div className='card'>
                                        {!user && (
                                            <div style={{ position: "relative" }}>
                                                <img className="gallery-img img-fluid mx-auto" src={JSON.parse(auction.video.video_url)[0]} alt="" style={{ width: "600px", height: "320px", objectFit: "cover" }} />
                                                <div className="gallery-overlay">
                                                </div>
                                            </div>
                                        )}
                                        {
                                            user && (
                                                <video
                                                    muted
                                                    autoPlay={true}
                                                    // className='auction-video'
                                                    ref={videoRef} // Ref to access the video element
                                                    onEnded={handleVideoEnded}
                                                // width="100%" // Set the video width
                                                // height="320" // Set the video height
                                                // onClick={togglePlay}
                                                >
                                                    <source
                                                        src={JSON.parse(auction.video.video_url)[1]}  // Provide the path to your video file
                                                        type="video/mp4"
                                                    />
                                                    Your browser does not support the video tag.
                                                </video>
                                            )
                                        }
                                        {/* {!isPlaying && (
                                            <div style={{ cursor: "pointer", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} onClick={togglePlay}>
                                                <img src={playIcon} alt="" width="50px"
                                                style={{ animation: "mover 1s infinite alternate" }} 
                                                />
                                            </div>
                                        )} */}
                                    </div>
                                </div>
                            </div>
                            <div className="box-content">
                                <p>
                                    {auction.video ? auction.video['description'] : ""}
                                </p>
                            </div>

                            <div className="box-content">
                                <div className="d-flex align-items-center justify-content-between mt-1">
                                    <div>
                                        <div className="flex-grow-1 text-muted"><h6>Start on:{formattedDateTime}</h6></div>
                                        <div className="flex-grow-1 text-muted">by <Link to="" className="text-body text-truncate">{auction.user['firstName']}</Link></div>

                                    </div>
                                    <div className="flex-shrink-1">
                                        <div className="d-block gap-3">
                                            <button type="button" className="btn btn-sm fs-12 btn-link text-body text-decoration-none px-0">
                                                <i className="ri-price-tag-3-fill text-muted align-bottom me-1"></i>Current bid: ${getMaxBidAmount(auction)}
                                            </button>
                                            <br />
                                            <button type="button" className="btn btn-sm fs-12 btn-link text-body text-decoration-none px-0">
                                                <i className=" ri-shopping-cart-2-fill text-muted align-bottom me-1"></i> Est Shipping Cost: ${auction.shipping_cost}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-3 d-flex justify-content-end '>
                                    <Button
                                        color="light"
                                        className='m-2'
                                        onClick={() => {
                                            props.close()
                                        }}
                                    >
                                        Close
                                    </Button>
                                    <ButtonGroup>
                                        <UncontrolledDropdown>
                                            <DropdownToggle tag="button" className="btn btn-primary m-2">
                                                Bid Now <i className="mdi mdi-chevron-down"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-md p-4">
                                                <div>
                                                    <div className="mb-2">
                                                        <label className="form-label" htmlFor="bid_amount_input">Bid amount</label>
                                                        <Input type="number" className="form-control" id="bid_amount_input" placeholder=""
                                                            value={bidAmount}
                                                            onChange={(e) => {
                                                                setBidAmount(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <Button className="btn btn-primary" onClick={() => {
                                                        if (parseFloat(bidAmount) < getMaxBidAmount(auction) + 1) {
                                                            Swal.fire({
                                                                title: 'Bid higher!',
                                                                text: "You need to place a bid that is higher than the maximum bid amount.",
                                                                icon: 'warn',
                                                                confirmButtonText: 'Ok'
                                                            })
                                                        } else {
                                                            var user = getLoggedInUser();
                                                            if (!user) {
                                                                history.push("/login?redirect_url=" + auction.id);
                                                            } else {
                                                                createBid({
                                                                    auction_id: auction.id,
                                                                    bid_amount: bidAmount
                                                                }).then(res => {
                                                                    if (res['error']) {
                                                                        Swal.fire({
                                                                            title: 'Error!',
                                                                            text: res['error'],
                                                                            icon: 'error',
                                                                            confirmButtonText: 'Ok'
                                                                        })
                                                                    } else {
                                                                        Swal.fire({
                                                                            title: 'Success!',
                                                                            text: "Submit successfully",
                                                                            icon: 'success',
                                                                            confirmButtonText: 'Ok'
                                                                        }).then(result => {
                                                                            window.location.reload();
                                                                        }, 1000);
                                                                    }
                                                                    props.close()
                                                                })
                                                            }

                                                        }
                                                        // tog_standard();
                                                    }}>Submit Bid</Button>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div>
                    )
                }

            </ModalBody>
        </Modal>
    );
};

export default AuctionDetailModal;