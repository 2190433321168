import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Modal, ModalHeader, Button, ModalBody, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Nav, NavItem, NavLink, Pagination, PaginationItem, ButtonGroup, PaginationLink, Progress, Row, TabContent, Table, TabPane, UncontrolledCollapse, UncontrolledDropdown } from 'reactstrap';
import AuctionCard from '../../../Widgets/AuctionCard';
import { getAllBids, getLoggedInUser, getUserDetail, getAllAdminVideos } from "../../../../helpers/fakebackend_helper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import Swal from 'sweetalert2';
import AuctionDetailModal from "../../../Widgets/AuctionDetailModal";


const AllAdminVideos = () => {
    const [modal, setModal] = useState(false);
    const [bidModalOpen, setBidModalOpen] = useState(false);
    const [bidAuction, setBidAuction] = useState({});
    const [companyType, setcompanyType] = useState(null);

    const [auctions, setAuctions] = useState([]);
    const [videos, setVideos] = useState([]);
    useEffect(() => {

        loadVideo();
        getUserDetail().then(res => {
            if (res) {
                if (!res['info']) {
                    res['info'] = userDetail.info;
                } else {
                    res['info'] = { ...userDetail.info, ...JSON.parse(res['info']) };
                }
                setUserDetail(res);
            }
        })

    }, [])

    const loadVideo = () => {
        getAllAdminVideos().then(res => {
            var temp = [...res];
            temp = temp.map(e => {
                e['createdAt'] = new Date(e['createdAt']).toLocaleString();
                e['duration'] = secondsToMinutesAndSeconds(e['duration']);
                return e;
            })
            setVideos(temp);
        })
    }
    const secondsToMinutesAndSeconds = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    }
    const user = getLoggedInUser();

    const [bids, setBids] = useState([]);
    const history = useHistory()
    const searchParams = new URLSearchParams(history.location.search);
    const url = searchParams.get("aid")

    const toggle = () => {
        if (modal) {
            setBidModalOpen(false);
        } else {
            setBidModalOpen(true);
        }
    };
    const convertDBDateTime = (dateString) => {

        // Split the string into date and time parts
        const [datePart, timePart] = dateString.split(", ");

        // Split the date part into year, month, and day
        const [day, month, year] = datePart.split("/").map(Number);

        // Split the time part into hours and minutes
        const [hours, minutes, secs] = timePart.split(":").map(Number);

        // Create a new Date object with the parsed values
        const dateObject = new Date(year, month - 1, day, hours, minutes);
        return dateObject;
    }
    const [auctionForm, setAuctionForm] = useState({
        "image_url": "",
        "video_id": "",
        "start_datetime": "",
        "auction_timeframe": 1,
        "end_datetime": "",
        "reserve_price": "0",
        "shipping_cost": "0"
    });
    const [modal_auction, setmodal_auction] = useState(false);

    function tog_auction(video) {
        if (video) {
            setAuctionForm({ ...auctionForm, video_id: video.id, image_url: JSON.parse(video.video_url)[0] });
        }
        setmodal_auction(!modal_auction);
    }
    const [userDetail, setUserDetail] = useState({
        "firstName": "",
        "lastName": "",
        "email": "",
        "phone": "",
        "info": {
            paymentAddress: "",
            country: "",
            city: "",
            // zipCode: "",
            description: "",
            avatar: ""
        },
    });
    const adminEmail = "alex.donaghue@gmail.com";
    if (userDetail.email !== adminEmail) {
        return <div>You do not have permission to view this page.</div>;
    }
    else {
        return (
            <React.Fragment>
                <section className="section pb-0 " id="hero">
                    {/* <div className="bg-overlay bg-overlay-pattern"></div> */}
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={8} sm={10}>
                                <div className="text-center ">
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </section>
                <div className="table-responsive" style={{ margin: "80px" }}   >
                    <Table className="table-borderless table-responsive mb-0" style={{ textAlign: "center" }}>
                        <thead>
                            <tr>

                                <th style={{ width: '4%' }}>ID#</th>
                                <th style={{ width: '8%' }}>Title of Art</th>
                                <th style={{ width: '20%' }}>Video</th>
                                <th style={{ width: '' }}>Description</th>
                                <th style={{ width: '5%' }}>Duration</th>
                                <th style={{ width: '12%' }}>Uploaded On</th>
                                {/* <th style={{ width: '12%' }}>Status</th> */}

                                <th style={{ width: '10%' }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {videos.sort((a, b) => a.id - b.id) // Sort videos by ID in ascending order
                                .map((video, index) => (
                                    <tr key={index}>
                                        <td style={{ width: '4%' }} className="ps-0" scope="row">{video.id}</td>
                                        <td style={{ width: '8%' }} className="text-muted">
                                            <div className="mt-4" onClick={() => { }}>
                                                <h5>{video.title}</h5>
                                                <img src={JSON.parse(video.video_url)[0]} width="180px" height="90px" style={{ borderRadius: "5px" }}></img>
                                            </div>
                                        </td>
                                        <td style={{ width: '20%' }}>
                                            <video muted controls style={{ width: '200px', height: '180px' }}>
                                                <source src={JSON.parse(video.video_url)[1]} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </td>
                                        <td style={{ width: '' }} className="text-muted text-ellipsis">{video.description}</td>
                                        <td style={{ width: '5%' }} className="text-muted ">{video.duration}</td>
                                        <td style={{ width: '12%' }} className="text-muted">{video.createdAt}</td>
                                        {/* <td style={{ width: '12%' }} className="text-muted">Status</td> */}
                                        <td style={{ width: '10%' }} className="text-muted">
                                            <ButtonGroup>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="button" className="btn btn-primary">
                                                        <i className="mdi mdi-chevron-down"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => {
                                                            const videoUrl = JSON.parse(video.video_url)[1]; // Extract the video URL
                                                            const a = document.createElement('a');
                                                            a.href = videoUrl;
                                                            a.download = `${video.title}.mp4`; // Set the download file name
                                                            document.body.appendChild(a);
                                                            a.click();
                                                            document.body.removeChild(a);
                                                        }}>
                                                            Save
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </div>
                <section>
                </section>
            </React.Fragment>
        );
    }
};

export default AllAdminVideos;