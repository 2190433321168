import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import {
    CardBody,
    CardHeader,
    Container,
    Card,
    Row,
    Col,
    Input,
    ModalHeader,
    Modal,
    ModalBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Label,
    Form,
} from "reactstrap";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import Select from "react-select";
import SellerChats from "../Ecommerce/EcommerceSellers/SellerChats";
import Swal from 'sweetalert2';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

import { EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";


// Import Images
import imgpattern from "../../assets/images/landing/img-pattern.png";

import defaultDemo from "../../assets/images/demos/default.png";
import saasDemo from "../../assets/images/demos/saas.png";
import materialDemo from "../../assets/images/demos/material.png";
import minimalDemo from "../../assets/images/demos/minimal.png";
import creativeDemo from "../../assets/images/demos/creative.png";
import modernDemo from "../../assets/images/demos/modern.png";
import interactiveDemo from "../../assets/images/demos/interactive.png";
import playIcon from "../../assets/images/users/play_icon1.png";
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import avatar1 from "../../assets/images/users/avatar-1.jpg";


// Import Images
import img1 from "../../assets/images/small/img-1.jpg";
import { Pagination, Navigation, Scrollbar, EffectCreative, Mousewheel, EffectFlip, EffectCoverflow } from "swiper";

//Import actions
import { getAllBids, getLoggedInUser, getPublicAllAuctions } from "../../helpers/fakebackend_helper";

import AuctionCard from '../../pages/Widgets/AuctionCard';
import AuctionDetailModal from "../Widgets/AuctionDetailModal";


const Home = () => {
    const [modal, setModal] = useState(false);
    const [bidModalOpen, setBidModalOpen] = useState(false);
    const [bidAuction, setBidAuction] = useState({});
    const [companyType, setcompanyType] = useState(null);

    const [auctions, setAuctions] = useState([]);

    const user = getLoggedInUser();

    const [bids, setBids] = useState([]);
    const history = useHistory()
    const searchParams = new URLSearchParams(history.location.search);
    const url = searchParams.get("aid")

    useEffect(() => {
        getPublicAllAuctions().then(res => {
            var temp = [...res].filter(e => {
                var now = new Date();
                var startTime = new Date(e['start_datetime']);
                var endTime = new Date(e['end_datetime']);
                return startTime < now && now < endTime;
            })
            if (url) {
                var redirectAuction = temp.filter(e => e['id'] == url)
                if (redirectAuction.length) {
                    setBidModalOpen(true);
                    setBidAuction(redirectAuction[0]);
                }
            }
            setAuctions(temp);
        })
        if (user) {
            loadBids();
            history.push("/landing");
        } else {
            history.push("/home");
        }
    }, []);

    const loadBids = () => {
        getAllBids().then(res => {
            var temp = [...res];
            temp = temp.filter(bid => bid.auction.bids.sort((b, a) => a.bid_amount - b.bid_amount)[0]['id'] == bid.id && new Date(bid.auction.end_datetime) < new Date() && !bid.payment);
            if (temp.length) {
                setTimeout(() => {
                    Swal.fire({
                        title: 'Winning Bid',
                        text: "You have a won bid for auctions. Do you want to buy it now?",
                        icon: 'success',
                        showCancelButton: true, // Display the "Cancel" button
                        confirmButtonText: 'Pay now',
                        cancelButtonText: 'Cancel', // Text for the "Cancel" button
                    }).then(res => {
                        if (res.isConfirmed) {
                            history.push('/pages-profile/' + temp[0]['id'])
                        }
                    })
                }, 2000);

            }
            setBids(temp);
        })
    }

    const toggle = () => {
        if (modal) {
            setBidModalOpen(false);
        } else {
            setBidModalOpen(true);
        }
    };

    const _handleOpenBidModal = (auction) => {
        setBidModalOpen(true);
        setBidAuction(auction);
    }

    return (
        <React.Fragment>
            <section className="section pb-0 " id="hero">
                {/* <div className="bg-overlay bg-overlay-pattern"></div> */}
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8} sm={10}>
                            <div className="text-center ">
                                {/* <div className="d-flex justify-content-center">
                                    <img src={logodark} className="card-logo card-logo-dark" alt="logo dark" height="60" />
                                    <img src={logolight} className="card-logo card-logo-light" alt="logo light" height="60" />
                                </div> */}
                                {/* <Link className="navbar-brand" to="/"> */}
                                {/* </Link> */}
                                {/* <h1 className="display-6 fw-semibold mb-3 lh-base">Your Video Art Journey Begins at  <span
                                    className="text-success">Artsoot </span></h1>

                                {!user && (
                                    <>
                                        <div className="d-flex gap-2 justify-content-center mt-4">
                                            <Link to="/register" className="btn btn-primary">Get Started <i className="ri-arrow-right-line align-middle ms-1"></i></Link>
                                            <Link to="/pages-pricing" className="btn btn-danger">View Plans <i className="ri-eye-line align-middle ms-1"></i></Link>
                                        </div>
                                    </>
                                )} */}
                            </div>
                        </Col>
                    </Row>
                    {/* <Row>
                        <h3>Featured Auctions</h3>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            pagination={{
                                el: '.swiper-pagination',
                                clickable: true,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }}
                            loop={true}
                            modules={[Pagination]}
                            className="mySwiper swiper responsive-swiper rounded gallery-light pb-4"
                        >
                            <div className="swiper-wrapper">
                                {
                                    auctions.map((auction, index) => (
                                        <SwiperSlide key={index}>
                                            <AuctionCard auction={auction} openBid={_handleOpenBidModal}></AuctionCard>
                                        </SwiperSlide>

                                    ))
                                }

                            </div>
                            <div className="swiper-pagination swiper-pagination-dark"></div>
                        </Swiper>
                    </Row> */}
                    {/* <Row>
                        {sellers.map((seller, key) => (
                            <React.Fragment key={key}>
                                <Col xl={6} lg={12}>
                                    <Card className="ribbon-box right overflow-hidden">
                                        <CardBody className="text-center p-4">
                                            {seller.isTrending && (
                                                <div className="ribbon ribbon-info ribbon-shape trending-ribbon">
                                                    <i className="ri-flashlight-fill text-white align-bottom"></i>{" "}
                                                    <span className="trending-ribbon-text">Upcomming</span>
                                                </div>
                                            )}

                                            <h5 className="mb-1 mt-4">
                                                <Link
                                                    to="apps-ecommerce-seller-details"
                                                    className="link-primary"
                                                >
                                                    {seller.label}
                                                </Link>
                                            </h5>
                                            <p className="text-muted mb-4">{seller.name}</p>
                                            <Row className="justify-content-center">
                                                <Col>
                                                    <div >
                                                        <div style={{ position: "relative" }} >
                                                            <img src={seller.img} alt="" height="300px" width="500px" style={{ backgroundColor: "#1d1b1b" }} />
                                                        </div>
                                                        {!seller.isTrending && (
                                                            <div style={{ cursor: "pointer", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                                                <img src={playIcon} alt="" width="100px" style={{ animation: "mover 1s infinite alternate" }} />
                                                            </div>

                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mt-4">
                                                <Col lg={6} className="border-end-dashed border-end">
                                                    <Link
                                                        to="apps-ecommerce-seller-details"
                                                        className="btn btn-light w-100"
                                                    >
                                                        View Details
                                                    </Link>
                                                </Col>
                                                <Col lg={6}>
                                                    <Link
                                                        to="apps-ecommerce-seller-details"
                                                        className="btn btn-light w-100"
                                                    >
                                                        Bid
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </React.Fragment>
                        ))}

                    </Row> */}
                </Container>

            </section>
            <section className="section">
                <Container>
                    {/* <h3>{auctions.length} Online Auctions</h3> */}
                    {auctions.map((auction, index) => (
                        <Row className="justify-content-center mt-4" key={index}>
                            <Col lg={8} >
                                <AuctionCard auction={auction} openBid={_handleOpenBidModal}></AuctionCard>
                                <hr />
                            </Col>
                        </Row>
                    )
                    )}
                </Container>

                <AuctionDetailModal open={bidModalOpen} auction={bidAuction} close={() => { setBidModalOpen(false) }}></AuctionDetailModal>
            </section>
            <section>
            </section>
        </React.Fragment>
    );
};

export default Home;